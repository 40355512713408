<template>
  <v-dialog 
    v-model="toggle"
    fullscreen 
    hide-overlay 
    transition="slide-y-reverse-transition"
  >
    <v-sheet
      id="onboarding"
      max-height="100%"
      class="sheet fill-height"
    >
      <v-btn
        v-if="slide==2"
        absolute
        top
        right
        fab
        icon
        color="white"
        class="mt-10"
        @click="toggleView(false)"
      >
        <v-icon>{{ icons.mdiClose }}</v-icon>
      </v-btn>
      <v-window 
        v-model="slide"
        class="fill-height"
        @change="onSlideChange"
      >
        <v-window-item class="fill-height">
          <v-sheet
            class="sheet d-flex flex-column justify-center flex-grow-1 text-center px-8 pb-12"
          >
            <img
              class="icon mx-auto"
              src="@/assets/onboarding-icon-1.svg"
            >
            <h4 class="title text-h6">
              Conte sobre você
            </h4>
            <p class="text text-body-1 mt-6 mb-12">
              Preencha seus dados para completar seu perfil conosco. Enviando seu perfil, você oficializa sua candidatura para ser um Parceiro Mobees.
            </p>
          </v-sheet>
        </v-window-item>

        <v-window-item class="fill-height">
          <v-sheet
            class="sheet d-flex flex-column justify-center flex-grow-1 text-center px-8 pb-12"
          >
            <img 
              class="icon mx-auto"
              src="@/assets/onboarding-icon-2.svg"
            >
            <h4 class="title text-h6">
              Avaliação do Perfil
            </h4>
            <p class="text text-body-1 mt-6 mb-12">
              A partir dos seus dados, vamos avaliar se seu perfil é compatível com nosso modelo de parceria.
            </p>
          </v-sheet>
        </v-window-item>

        <!-- <v-window-item 
          v-if="!ios"
          class="fill-height"
        >
          <v-sheet
            class="sheet d-flex flex-column justify-center flex-grow-1 text-center px-8 pb-12"
          >
            <img src="@/assets/onboarding-icon-3.svg">
            <h4 class="title text-h6">
              Habilite o GPS
            </h4>
            <p class="text text-body-1 mt-6 mb-12">
              Para garantir uma avaliação mais justa, permita o compartilhamento de seu GPS. Fique tranquilo, suas informações estão seguras conosco.
            </p>
            <v-btn 
              large
              color="primary"
              class="btn-location grey--text text--darken-4 mt-4 mb-8"
              :loading="btnLocation.loading"
              @click="requestLocation"
            >
              Habilitar GPS
            </v-btn>  
          </v-sheet>
        </v-window-item> -->

        <v-window-item 
          class="fill-height"
        >
          <v-sheet
            class="sheet d-flex flex-column justify-center flex-grow-1 text-center px-8 pb-12"
          >
            <img 
              class="icon mx-auto"
              src="@/assets/onboarding-icon-4.svg"
            >
            <h4 class="title text-h6">
              <span>
                Fique atento às notificações
              </span>
              <!-- <span v-else>
                Ative as Notificações
              </span> -->
            </h4>
            <p class="text body-1 mt-6 mb-12">
              <span>
                Vamos avisar quando tivermos notícias! Para isso, fique atento às notificações do nosso app e ao seu email.
              </span>
              <!-- <span v-else>
                Queremos avisar quando tivermos novidades sobre seu processo! Para isso habilite as notificações para nosso app.
              </span> -->
            </p>
            <!-- <v-btn 
              v-if="ios"
              large
              color="primary"
              class="btn-push grey--text text--darken-4 mt-4 mb-8"
              @click="requestPush"
            >
              Ativar Notificações
            </v-btn>   -->
          </v-sheet>
        </v-window-item>

        <v-window-item 
          class="fill-height"
        >
          <v-sheet
            class="sheet d-flex flex-column justify-center flex-grow-1 text-center px-8 pb-12"
          >
            <v-progress-circular
              :size="48"
              :width="4"
              color="secondary"
              indeterminate
              class="mx-auto mb-12"
              style="opacity: .4"
            />
            <h4 class="title text-h6">
              Ok, vamos lá!
            </h4>
            <p class="text text-body-1 mt-6 mb-8">
              Carregando suas informações...
            </p>
          </v-sheet>
        </v-window-item>
      </v-window>
      <v-card-actions 
        class="controls pa-8 flex-column justify-center"
      >
        <v-btn 
          v-if="slide<=1"
          text
          small
          color="secondary"
          class="btn-next mb-6"
          @click="nextSlide"
        >
          Arraste para o lado
        </v-btn>
        <v-btn 
          v-else-if="slide==2"
          text
          small
          color="secondary"
          class="btn-finish mb-6"
          @click="toggleView(false)"
        >
          Continuar
        </v-btn>  
        <v-item-group
          v-if="slide<2"
          v-model="slide"
          mandatory
          active-class="active"
          class="dots text-center"
        >
          <v-item
            v-for="n in 3"
            :key="`slide-${n}`"
            v-slot:default="{ active, toggle }"
            class="dot"
          >
            <v-btn
              :input-value="active"
              icon
              :small="!active"
              :color="active ? 'primary' : 'secondary'"
              @click="toggle"
            >
              <v-icon>{{ icons.dot }}</v-icon>
            </v-btn>
          </v-item>
        </v-item-group>
      </v-card-actions>
    </v-sheet>
  </v-dialog>
</template>

<style>

  #onboarding {
    height: 100% !important;
  }

  #onboarding .controls {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: .75;
  }

  #onboarding .icon {
    max-width: 180px;
  }

</style>

<script>

  // Icons
  import {
    mdiClose,
    mdiCheckboxBlankCircle,
    mdiCircleMedium,
  } from '@mdi/js'

  // Utilities
  import services from '@/services.js'
  import { get, sync } from 'vuex-pathify'
  import _ from 'lodash';
  import device from 'mobile-device-detect';


  export default {
    name: 'Onboarding',

    props: {
      // installed: {
      //   type: Boolean,
      //   default: false,
      // }
    },

    data: () => ({
      api: process.env.VUE_APP_ROOT_API,
      icons: {
        mdiClose,
        dot: mdiCircleMedium,
        dotActive: mdiCheckboxBlankCircle,
      },
      ios: device.isIOS,
      btnLocation: {
        loading: false
      },
      btnPush: {
        loading: false
      },
      installed: typeof mobees != 'undefined'
    }),

    computed: {
      toggle: sync('signup/onboarding@toggle'),
      slide: sync('signup/onboarding@slide'),
      token: get('user/auth@token'),
      cpf: get('user/cpf'),
      gps: sync('user/profile@gps'),
      push: sync('user/profile@push'),
      toast: sync('app/toast'),

    },

    methods: {
      ...services,

      toggleView (b) {
        this.toggle = b;
        if (!b&&this.slide>=2) {
          localStorage.setItem("onboarding", "opened");
        }
      },

      async requestLocation () {
        if (this.installed) {
          this.btnLocation.loading = true; 
          const cpf = this.rawCPF(this.user.cpf);
          const token = this.token;
          mobees.requestGpsPermission((result) => {
            mobees.hasGpsPermission((result) => {
              this.gps = (result=='true');
              console.log('GPS: '+this.gps);
              
              this.$api.post('/setgeolocationpermission', {
                cpf: cpf,
                authTk: token,
                permitted: this.gps
              })
              .then(response => {
                console.log('setGeolocationPermission => ', response);
                if(response.data.retorno==200){
                  // saved
                  this.trackEvent('geolocation', this.gps ? 'enabled' : 'disabled');
                }else{
                  this.trackEvent('geolocation', 'set state error');
                }
              })
              this.nextSlide();
              this.btnLocation.loading = false; 
            }, () => {
              this.nextSlide();
              this.btnLocation.loading = false; 
              // TODO handle native access error
            });
          });
        }
      },

      async requestPush () {
        if (this.installed) {
          this.btnPush.loading = true; 
          mobees.requestPushPermission((result) => {
            this.gps = (result=='true');
            this.btnPush.loading = false;
            this.nextSlide();
          }, () => {
            // TODO handle native access error
            this.btnPush.loading = false;
            this.nextSlide();
          });
        }
      },

      nextSlide () {
        this.slide += 1;
      },

      onSlideChange () {
        if (this.slide==3||(this.ios&&this.slide==2)) {
          setTimeout(() => this.toggleView(false), 1250);
        }
      }

    },

    mounted () {
      const onboarding = localStorage.getItem("onboarding");
      console.log('onboarding', onboarding);
      if (onboarding==null) {
        this.toggleView(true);
      }else{
        this.toggleView(false);
      }

    },

  }
</script>
